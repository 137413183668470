<template>
  <div v-if="destination">
    <user-group-selector
      v-model="userGroup"
      :label="`${$t('selectors.select')} ${$tc('load.destination', 1)}`"
      return-object
      @input="updateUserGroup"
      @click:clear="$emit('change', '')"
    />

    <base-address-card
      v-if="userGroup && userGroup.attributes.name"
      class="py-3 mb-5 orange lighten-5"
      :name="userGroup.attributes.name"
      :address="userGroup.attributes.customAttributes.address"
    >
    </base-address-card>
  </div>
</template>

<script>
import UserGroupSelector from '@/views/user-group/components/UserGroupSelector'
import { USER_GROUP } from '@/models/user.group'
export default {
  components: {
    UserGroupSelector
  },
  props: {
    order: {
      type: Object,
      required: true
    },
    destination: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      userGroup: USER_GROUP('supplier')
    }
  },
  async created() {
    const orderId = this.order.id
    if (orderId) {
      const { userGroupId } = this.destination.attributes.customAttributes
      if (userGroupId) this.userGroup = await this.fetchUserGroup(userGroupId)
    }
  },
  methods: {
    async fetchUserGroup(userGroupId) {
      return this.$store.dispatch('userGroup/fetch', userGroupId)
    },
    updateUserGroup(value) {
      if (!value) return
      Object.assign(
        this.destination.attributes.location,
        this.userGroup.attributes.customAttributes.address
      )
      Object.assign(this.destination.attributes.customAttributes, {
        userGroupId: this.userGroup.id
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
