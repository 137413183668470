<template>
  <div>
    <base-selector
      :label="$t('item.specie')"
      :items="species"
      :item-text="composeItemText"
      return-object
      :value="item.attributes.customAttributes"
      @input="updateValue"
    />
    <v-row dense>
      <v-col>
        <base-field-input
          v-model="item.attributes.volume"
          :label="$t('item.volume')"
        />
      </v-col>
      <v-col
        ><base-units-select
          v-model="item.attributes.volumeUnit"
          name="volume"
          :filters="['CM', 'MM', 'CORD', 'BF']"
        /> </v-col
    ></v-row>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    userGroupId: {
      type: String,
      default: ''
    },
    order: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      species: []
    }
  },
  watch: {
    userGroupId(value) {
      this.fetchUserGroupSpecies(value)
    }
  },

  methods: {
    composeItemText(item) {
      if (!item || !item.specie) return ''
      let labels = [
        this.$t(`species.${item.specie?.text}`),
        item.category,
        item.type,
        item.origin
      ]
      return labels.filter(label => Boolean(label)).join('/')
    },
    updateValue(userGroupSpecie) {
      if (!userGroupSpecie) return
      delete userGroupSpecie.cites
      this.item.attributes.customAttributes = userGroupSpecie
      this.item.attributes.supplierId = this.userGroupId
      this.order.attributes.customAttributes.specie = userGroupSpecie.specie
    },
    async fetchUserGroupSpecies(value) {
      if (!value) return (this.species = [])
      const response = await this.$store.dispatch('userGroup/fetch', value)
      this.species = response.attributes.customAttributes.speciesInfo
    }
  }
}
</script>
