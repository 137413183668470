<template>
  <v-row no-gutters>
    <v-col cols="12">
      <UserGroupSelector
        v-model="userGroup"
        :label="`${$t('selectors.select')} ${$tc('user.supplier', 1)}`"
        category="supplier"
        return-object
        @input="updateOrderAndLoad"
        @click:clear="$emit('change', '')"
      />
    </v-col>
    <v-col v-if="userGroup" cols="12">
      <BaseUserSelect
        v-model="selectedUsers"
        :label="
          `${$t('selectors.selectContactFrom')} ${$tc('user.supplier', 1)}`
        "
        :multiple="true"
        :roles="roles"
        :group-id="userGroup.id"
        @input="updateUsers"
      />
    </v-col>
    <v-col v-else cols="12">
      <BaseSelector
        :hint="$t('selectors.selectFirst', { user: $tc('user.supplier', 1) })"
        persistent-hint
        disabled
      />
    </v-col>

    <v-col v-if="parties.length" cols="12" class="mb-5">
      <p class="green--text">
        {{ $t('selectors.greenContacts') }}
      </p>
      <template v-for="(party, i) in parties">
        <v-chip
          :key="i"
          label
          class="my-2 mr-2 text-capitalize"
          close
          :color="party.id ? 'green lighten-3' : 'amber lighten-3'"
          text-color="white"
          close-icon="mdi-delete"
          @click:close="removeParty(i)"
        >
          <v-avatar left>
            <v-icon>mdi-account-outline</v-icon>
          </v-avatar>
          {{ party.attributes.contactName || party.attributes.companyName }}
          [{{ party.attributes.customAttributes.role }}]
        </v-chip>
      </template>
    </v-col>
    <v-col v-if="userGroup" cols="12">
      <BaseAddressCard
        color="green lighten-5"
        :name="userGroup.attributes.name"
        :address="userGroup.attributes.customAttributes.address"
      />
    </v-col>
  </v-row>
</template>

<script>
import UserGroupSelector from '@/views/user-group/components/UserGroupSelector'
import { PARTY } from '@/models/party'
export default {
  components: {
    UserGroupSelector
  },
  props: {
    order: {
      type: Object,
      required: true
    },
    value: {
      type: Array,
      required: true
    },
    origin: {
      type: Object,
      required: true
    },
    destination: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      userGroup: null,
      roles: 'supplier,forest_manager',
      selectedUsers: [],
      parties: []
    }
  },

  async created() {
    const orderIds = this.order.id
    if (orderIds) {
      this.parties = await this.filterParties(orderIds)
      const groupId = this.parties[0]?.attributes?.customAttributes.userGroupId
      if (groupId) {
        this.userGroup = await this.$store.dispatch('userGroup/fetch', groupId)
        this.emitUserGroupId()
      }
      const userIds = this.parties
        .map(party => party?.attributes?.userId)
        .join(',')
      if (userIds)
        this.selectedUsers = await this.$store.dispatch('user/filter', {
          userIds
        })
      this.$emit('input', this.parties)
    }
  },
  methods: {
    async filterParties(orderIds) {
      if (!orderIds) return
      return await this.$store.dispatch('party/filter', {
        orderIds
      })
    },
    async updateUsers(value) {
      if (!value) return this.$emit('input', this.parties)
      if (this.order.id) {
        this.parties = await this.filterParties(this.order.id)
      }
      this.selectedUsers.forEach(user => {
        const userIsParty = this.checkIfUserIsParty(user)
        if (userIsParty) return
        const party = PARTY('Origin', 'Provider')

        const attributes = {
          userId: user.id,
          orderId: this.order.id,
          companyName: this.userGroup.attributes.name,
          contactName: user.attributes.info.name,
          customAttributes: {
            role: user.attributes.roles[1],
            userGroupId: this.userGroup.id
          }
        }
        Object.assign(party.attributes, attributes)
        this.parties.push(party)
      })
      this.$emit('input', this.parties)
    },

    checkIfUserIsParty(user) {
      if (!this.parties.length) return
      const userIds = this.parties.map(party => party.attributes.userId)
      return userIds.includes(user.id)
    },
    updateOrderAndLoad(userGroup) {
      if (!userGroup) return
      const load = this.origin
      this.order.attributes.customAttributes.certificates =
        userGroup.attributes.customAttributes.certificates
      Object.assign(
        load.attributes.location,
        this.userGroup.attributes.customAttributes.address
      )
      this.emitUserGroupId()
    },
    removeParty(index) {
      if (this.order.id)
        this.$store.dispatch('party/delete', this.parties[index])
      this.removeSelectedUser(index)
      this.parties.splice(index, 1)

      this.$emit('input', this.parties)
    },
    removeSelectedUser(index) {
      const userIndex = this.selectedUsers
        .map(user => user.id)
        .indexOf(this.parties[index].attributes.userId)
      this.selectedUsers.splice(userIndex, 1)
    },
    emitUserGroupId() {
      this.$emit('change', this.userGroup.id)
    }
  }
}
</script>
