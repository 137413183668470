<template>
  <v-row no-gutters>
    <v-col cols="12">
      <BaseFieldInput
        v-model="order.attributes.customAttributes.name"
        placeholder="1-20"
        :label="`${$tc('purchase.name', 1)}#`"
        :rules="rules"
        autofocus
      />
    </v-col>
    <v-col cols="12"></v-col>
    <v-col cols="12">
      <BaseFieldInput
        v-model="order.attributes.customAttributes.purchaseOrders"
        :label="`Referenced ${$tc('purchase.purchaseOrder', 2)}#`"
        placeholder="02020/01"
      />
    </v-col>
    <v-col cols="12">
      <BaseFieldInput
        v-model="order.attributes.customAttributes.invoiceNumber"
        placeholder="IN00001"
        :label="`${$tc('purchase.invoiceNumber', 2)}#`"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      rules: [
        v =>
          !!v ||
          `${this.$tc('purchase.name', 1)} ${this.$t('hints.isRequired')}`
      ]
    }
  }
}
</script>
