<template>
  <v-autocomplete
    :label="`${$t('selectors.select')} ${$tc('user.forestManager', 2)}`"
    v-bind="$attrs"
    outlined
    :value="value"
    item-text="attributes.name"
    :items="userGroups"
    clearable
    @input="$emit('input', $event)"
    @click:clear="$emit('click:clear', $event)"
  >
    <template v-slot:append-item>
      <v-list-item-action>
        <v-btn small text color="primary" :to="`user-groups/forest_manager/new`"
          ><v-icon left>mdi-plus</v-icon>{{ $t('actions.new') }}
          {{ $tc('user.forestManager', 1) }}</v-btn
        >
      </v-list-item-action>
    </template>
  </v-autocomplete>
</template>

<script>
import { ORGANIZATION_ID } from '@/common/config.js'
export default {
  inheritAttrs: false,
  props: {
    value: {
      type: [Object, Array],
      default: () => {}
    }
  },
  data() {
    return {
      userGroups: []
    }
  },
  async created() {
    await this.filterUserGroups()
  },
  methods: {
    async filterUserGroups() {
      this.userGroups = await this.$store.dispatch('userGroup/filter', {
        organizationId: ORGANIZATION_ID(),
        category: 'forest_manager'
      })
    }
  }
}
</script>
