<template>
  <base-form-layout
    :title="`${action} ${$tc('purchase.name', 1)}`"
    @submit="onSubmit"
  >
    <v-form v-if="!isLoading" ref="form">
      <v-row
        ><v-col cols="6">
          <h6 class="text-h6 mb-5">
            <v-icon left>mdi-text-box</v-icon> {{ $t('common.details') }}
          </h6>

          <v-divider class="mb-8"></v-divider>
          <PurchaseOrderEditForm :order="order" />
        </v-col>
        <v-col cols="6">
          <h6 class="text-h6 mb-5">
            <v-icon left>mdi-truck-delivery-outline</v-icon
            >{{ $t('load.deliveryTo') }}
          </h6>
          <v-divider class="mb-8"></v-divider>
          <DestinationLocation :order="order" :destination="destination" />
          <DestinationETA :destination="destination" />
          <IncotermSelector
            v-model="order.attributes.incoterms"
            :label="$t('selectors.incoterms')"
          />
        </v-col> </v-row
      ><v-row>
        <v-col cols="6">
          <h6 class="text-h6 mb-5">
            <v-icon left>mdi-account-details-outline</v-icon
            >{{ $tc('user.supplier', 1) }}
          </h6>
          <v-divider class="mb-8"></v-divider>
          <SupplierSelector
            v-model="parties"
            :order="order"
            category="purchase"
            :origin="origin"
            :destination="destination"
            @change="updateUserGroupId"
          />

          <ForestManagerSelector
            v-model="order.attributes.customAttributes.forestManagerIds"
            class="mt-4"
            item-value="id"
            multiple
          />
        </v-col>
        <v-col cols="6">
          <h6 class="text-h6 mb-5">
            <v-icon left>mdi-pine-tree</v-icon
            >{{ $t('item.specieAndQuantity') }}
          </h6>
          <v-divider class="mb-8"></v-divider>
          <ItemQuotationEdit
            :user-group-id="userGroupId"
            :order="order"
            :item="item"
          />
        </v-col>
      </v-row>
    </v-form>
  </base-form-layout>
</template>

<script>
import IncotermSelector from '@/views/sale-order/components/IncotermSelector'
import SupplierSelector from './components/SupplierSelector'
import ForestManagerSelector from './components/ForestManagerSelector'
import PurchaseOrderEditForm from './components/PurchaseOrderEditForm'
import DestinationLocation from '@/views/load/components/DestinationLocation'
import DestinationETA from '@/views/load/components/DestinationETA'
import ItemQuotationEdit from '@/views/item-quotation/components/ItemQuotationEdit'
import { FormMixin } from '@/mixins/form.mixin'
import { PURCHASE_ORDER } from '@/models/purchase.order'
import { ITEM_QUOTATION } from '@/models/item.quotation'
import { LOAD } from '@/models/load'
import { mapActions } from 'vuex'
export default {
  components: {
    IncotermSelector,
    SupplierSelector,
    PurchaseOrderEditForm,
    DestinationLocation,
    DestinationETA,
    ItemQuotationEdit,
    ForestManagerSelector
  },
  mixins: [FormMixin],
  props: {
    orderId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      userGroupId: null,
      order: PURCHASE_ORDER(),
      origin: LOAD('Origin'),
      destination: LOAD('Destination'),
      item: ITEM_QUOTATION(),
      parties: [],
      isLoading: true
    }
  },
  computed: {
    action() {
      return this.$t(this.orderId ? 'actions.edit' : 'actions.new')
    }
  },

  async created() {
    const orderId = this.orderId
    if (orderId) {
      this.order = await this.fetchOrder(orderId)
      this.origin = await this.filterLoads(orderId, 'Origin')
      this.destination = await this.filterLoads(orderId, 'Destination')
      this.item = await this.fetchItemQuotation(orderId)
    }
    this.isLoading = false
  },
  methods: {
    ...mapActions({
      addNotification: 'notification/add'
    }),
    updateUserGroupId(value) {
      this.userGroupId = value
    },
    async fetchItemQuotation(orderId) {
      const response = await this.$store.dispatch('itemQuotation/filter', {
        orderId
      })
      return response[0]
    },
    async fetchOrder(orderId) {
      return await this.$store.dispatch('purchaseOrder/fetch', orderId)
    },
    async filterLoads(orderIds, category) {
      const response = await this.$store.dispatch('load/filter', {
        orderIds,
        category
      })
      return response[0]
    },

    async onSubmit() {
      this.beforeSubmit = false

      const resources = [
        this.order,
        this.origin,
        this.destination,
        ...this.parties,
        this.item
      ]
      this.orderId
        ? await this.updateOrder(resources)
        : await this.createOrder(resources)
    },
    async updateOrder(resources) {
      resources = resources.filter(Boolean)
      const response = await this.submitOrder('update', resources[0])
      if (!response) return
      for (let i = 1; i < resources.length; i++) {
        const resource = resources[i]
        const module = resource.type
        const action = resource.id ? 'update' : 'create'
        await this.$store.dispatch(`${module}/${action}`, resource)
      }
      this.$router.go(-1)
    },

    async createOrder(resources) {
      resources = resources.filter(Boolean)
      const response = await this.submitOrder('create', resources[0])
      if (!response) return
      const orderId = response.id
      for (let i = 1; i < resources.length; i++) {
        const resource = resources[i]
        Object.assign(resource.attributes, { orderId })
        const module = resource.type
        await this.$store.dispatch(`${module}/create`, resource)
      }
      this.addNotification([{ status: '200', title: 'Successfully created' }])

      this.$router.go(-1)
    },
    async submitOrder(action, order) {
      try {
        const response = await this.$store.dispatch(
          `purchaseOrder/${action}`,
          order
        )
        this.addNotification({
          status: '200',
          color: 'success',
          time: 2000,
          messages: { title: 'Successfully updated' }
        })
        return response
      } catch (error) {
        if (!error.response) return
        const {
          status,
          data: { errors }
        } = error.response
        return this.addNotification({
          status,
          messages: errors,
          color: 'error',
          time: 10000
        })
      }
    }
  }
}
</script>
