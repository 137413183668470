import { ORGANIZATION_ID } from '@/common/config.js'
import store from '@/store/index.js'

export const PURCHASE_ORDER = () => ({
  type: 'order',
  attributes: {
    userId: store.state.auth.authUser.data.id,
    organizationId: ORGANIZATION_ID(),
    incoterms: '-',
    category: 'sea',
    subCategory: 'purchase',
    referenceNumber: Date.now().toString(),
    details: '',
    status: 'requested',
    clientOrganizationId: null,
    customAttributes: {
      name: '',
      blockchain: null,
      purchaseOrders: '',
      invoiceNumber: '',
      forestManagerIds: [],
      billOfLadingNumber: '',
      estimatedDeliveryDate: '',
      vessel: '',
      items: [],
      shippedVolume: '',
      shippedVolumeUnit: 'm³',
      metricTons: null,
      specie: {},
      certificates: []
    }
  }
})
